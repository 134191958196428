.NavBar {
  background-color: dimgray;
  font-size: 20px;
  color: white;
  height: 70px;
}

.pe-nav-button{
  border: none;
  background-color: white;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
}

.pe-hr{
  color: black;
  border-color: black;
  padding-top: 0%;
  padding-bottom: 0%;
  margin-top: 0%;
  margin-bottom: 0%;
  margin-right: 10%;
  margin-left: 10%;
}


@import "~react-image-gallery/styles/css/image-gallery.css";

.main-nav-active {
  color: #ef5611 !important; 
}

.pe-app-logo {
  /* height: 10vmin; */
  /* pointer-events: none; */
  /* float: left; */
  /* max-width: 25%;*/
  min-width: 20px; 
  box-sizing: border-box;
  width: 5%;
}
.pe-color{
  color: #ef5611 !important;
}

.pe-link{
  color: grey;
}
/* 
.Portfolio-images {
  height: 40vmin;
} */

.Portfolio {
  align-items: center;
  text-align: center;
}

.pe-header {
  padding-top: 1%;
  font-size: medium;
  text-align: center;
  background-color: white;
  min-height: 50%;
  color: dimgray;
}

.pe-header-secondary{
  padding-top: 1%;
  font-size: large;
  text-align: left;
  background-color: white;
  color: #606060;
  font-weight: bold;
}

.pe-main-text {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.pe-h1 {
  font-size: x-large;
  text-align: center;
  color: #282828;
  padding-top: 25px;
  padding-bottom: 10px;

}

.App-header {
  /* background-color: #282c34; */
  /* background-color: whitesmoke;
  min-height: 10vh; */
  /* display: flex; */
  /* flex-direction: column; */
  /* align-items: left; */
  /* justify-content: left; */
  font-size: calc(10px + 2vmin);
  color: white;
  /* border: 2px solid grey; */
  /* display: table-cell; */

}

.App-link {
  color: #61dafb;
}

.pe-paragraph, li, ul, p{
  color: dimgray;
  font-size: large;
  font-family: sans-serif;
}
